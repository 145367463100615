<script setup lang="ts">
import { Frequency, ContentFileImageVariant, tryImageVariant, ContentButtonVariant } from '#imports'

const props = defineProps<{
  title?: string
  caption?: string
  ctaText?: string
  ctaLink?: string
  backgroundImages?: Array<{
    image: ContentFile
    altText?: string
  }>
  backgroundOffset?: number
  transitionInterval?: number
  searchTitle: string
  searchButtonText: string
}>()

const { t } = useI18n()
const router = useRouter()

// Fetch categories for the first dropdown
const { categories } = await useCategories()

// Set up frequency options for the second dropdown
const frequencies = Object.values(Frequency).filter(freq => freq != Frequency.MultipleDays)

// Search form state
const selectedCategoryIds = ref<id[]>([])
const selectedFrequencies = ref<Frequency[]>([])

// Current image index
const currentImageIndex = ref(0)

// Handle image rotation
const interval = ref<NodeJS.Timeout>()

const backgroundImages = props.backgroundImages || []

onMounted(() => {
  if (backgroundImages.length > 1) {
    const intervalTime = (props.transitionInterval || 5) * 1000
    interval.value = setInterval(() => {
      currentImageIndex.value = (currentImageIndex.value + 1) % backgroundImages.length
    }, intervalTime)
  }
})

onUnmounted(() => {
  if (interval.value) {
    clearInterval(interval.value)
  }
})

// Handle search button click
const handleSearch = () => {
  const query: Record<string, string | string[] | id[]> = {}

  // Add category filter if selected
  if (selectedCategoryIds.value) {
    query['filter[categories_id_in][]'] = selectedCategoryIds.value
  }

  // Add frequency filter if selected
  if (selectedFrequencies.value) {
    query['filter[frequency_in][]'] = selectedFrequencies.value
  }

  // Navigate to search page with filters
  router.push({
    path: '/vacancies/search',
    query,
  })
}

// This determines how much vertical space we create for the background image
const verticalPadding = 'py-32'

// we only use 'rounded' | 'straight' right now
// needs to be refactored in new version.
const { defaultShapes } = useTheme()
const defaultRadius = computed(() => defaultShapes.value?.card)
const radius = computed(() => defaultRadius.value ?? 'rounded')

// Calculate background position based on offset
// TODO: implement this. Also discuss with @maud and @roos
const backgroundPosition = computed(() => {
  const offset = props.backgroundOffset ?? 20
  return `${100 - offset}% center`
})

const transitionStyle: 'fade' | 'slide' = 'fade'
</script>

<template>
  <ContentBlockViewPartialContainer
    background-color="transparent"
    padding="default"
  >
    <div
      class="relative z-10 grid grid-cols-1 gap-4 py-0 md:grid-cols-2"
    >
      
      
      <div class="bg-theme-background z-10 flex flex-col items-start justify-center py-12 pr-12">
        
        <BaseHeading
          as="h2"
          size="4xl"
          class="text-theme-content mb-4 text-4xl font-bold leading-tight"
        >
          {{ title }}
        </BaseHeading>

        <BaseParagraph class="text-theme-content dark:text-muted-100 mb-6 text-lg">
          {{ caption }}
        </BaseParagraph>

        <BaseContentButton
          :to="ctaLink"
          :variant="ContentButtonVariant.Secondary"
        >
          {{ ctaText }}
          <Icon name="heroicons:arrow-right" class="ml-2 size-5" />
        </BaseContentButton>
      </div>

      
      <div
        class="relative flex  items-center justify-center"
        :class="{
          [verticalPadding]: true,
        }"
      >
        
        <TransitionGroup
          tag="div"
          class="absolute inset-0 z-0"
          enter-active-class="transition-all duration-1000"
          :enter-from-class="transitionStyle === 'fade' ? 'opacity-0' : 'opacity-0 translate-x-full'"
          :enter-to-class="transitionStyle === 'fade' ? 'opacity-100' : 'opacity-100 translate-x-0'"
          leave-active-class="transition-all duration-1000 absolute"
          :leave-from-class="transitionStyle === 'fade' ? 'opacity-100' : 'opacity-100 translate-x-0'"
          :leave-to-class="transitionStyle === 'fade' ? 'opacity-0' : 'opacity-0 -translate-x-full'"
        >
          <img
            v-for="(backgroundImage, index) in backgroundImages"
            v-show="currentImageIndex === index"
            :key="backgroundImage.image.id"
            :src="tryImageVariant(backgroundImage.image, ContentFileImageVariant.Large)"
            :alt="backgroundImage.altText || 'Achtergrondafbeelding'"
            class="absolute inset-0 z-0 size-full h-full transform-gpu object-cover"
            :class="{
              'rounded-xl': radius == 'rounded',
              'rounded-none': radius == 'straight',
            }"
            :style="{
              transform: `translateX(${backgroundOffset}%)`,
            }"
          >
        </TransitionGroup>

        <div
          class="dark:bg-muted-950 z-10 mx-4 w-full max-w-md bg-white p-6"
          :class="{
            'rounded-xl': radius == 'rounded',
            'rounded-none': radius == 'straight',
          }"
        >
          <h2 v-if="searchTitle" class="mb-4 text-center text-xl font-semibold text-gray-800">
            {{ searchTitle }}
          </h2>

          <div class="flex flex-col gap-y-4">
            
            <FormListField
              name="category"
              :label="t('category')"
              :items="categories"
              :label-factory="category => category.name"
              multiple
              @on-select="(categories: Category[]) => selectedCategoryIds = (categories || []).map(c => c.id)"
            />

            
            
            <FormListField
              name="frequency"
              :label="t('frequency')"
              :items="frequencies"
              :label-factory="frequency => t(`frequencies.${frequency || 'all'}`)"
              multiple
              @on-select="(frequencies: Frequency[]) => selectedFrequencies = frequencies"
            />

            <BaseContentButton
              :variant="ContentButtonVariant.Secondary"
              to="/vacancies/search"
              class="w-max"
              @click="handleSearch"
            >
              {{ searchButtonText || t('search') }}
            </BaseContentButton>
          </div>
        </div>
      </div>
    </div>
  </ContentBlockViewPartialContainer>
</template>

<i18n lang="json">
{
  "en": {
    "category": "What interests you?",
    "frequency": "How often?",
    "selectCategory": "Select a category",
    "selectFrequency": "Select frequency",
    "frequencies": {
      "all": "Doesn't matter",
      "once": "Once",
      "flexible": "Flexible",
      "regularly": "Regularly"
    },
    "search": "Search"
  },
  "nl": {
    "category": "Wat spreekt je aan?",
    "frequency": "Hoe vaak?",
    "selectCategory": "Selecteer een categorie",
    "selectFrequency": "Selecteer frequentie",
    "frequencies": {
      "all": "Maakt niet uit",
      "once": "Eenmalig",
      "flexible": "Flexibel",
      "regularly": "Regelmatig"
    },
    "search": "Zoeken"
  }
}
</i18n>
