<script setup lang="ts">
import { string, object } from 'yup'

const { t } = useI18n({ useScope: 'local' })

// Schema with the contact form identifier
const schema = object().shape({
  formId: string().required(),
})

useBlockFormSchema(schema)

</script>

<template>
  <div>
    <FormField name="formId" :label="t('formId')" />
  </div>
</template>

<i18n>
{
  "en": {
    "formId": "Formulier ID"
  },
  "nl": {
    "formId": "Formulier ID"
  }
}
</i18n>
